<template>
  <div class="content">
    <div class="title">
      意见标题
      <span class="xx">*</span>
    </div>
    <van-cell-group inset>
      <van-field v-model="title" placeholder="请输入意见标题" />
    </van-cell-group>
    <div class="title two">
      描述信息
      <span class="xx">*</span>
    </div>
    <van-cell-group inset>
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入您的描述信息"
        show-word-limit
      />
    </van-cell-group>
    <div class="title two">上传图片(0/6)</div>
    <van-cell-group class="updata" inset>
      <van-field>
        <template #input>
          <div class="up-box">
            <img v-for="item in imgdata" :key="item" :src="item" alt />
          </div>
          <van-uploader :after-read="afterRead" v-if="imgdata.length===6?false:true">
            <div class="up">
              <van-icon color="#909090" name="plus" size="25px" />
            </div>
          </van-uploader>
        </template>
      </van-field>
    </van-cell-group>
    <div class="title two">联系人</div>
    <van-cell-group inset>
      <van-field v-model="username" placeholder="请输入联系人姓名" />
    </van-cell-group>
    <div class="title two">手机号</div>
    <van-cell-group inset>
      <van-field v-model="phone" placeholder="请输入手机号" />
    </van-cell-group>
    <div class="title two">电子邮箱</div>
    <van-cell-group inset>
      <van-field v-model="emil" placeholder="请输入电子邮箱" />
    </van-cell-group>
    <div class="but" @click="submit">提 交</div>
  </div>
</template>

<script>
import { file, commitFeedback } from '@/api/user'
import axios from 'axios'
import { Toast } from 'vant'
export default {
  data() {
    return {
      title: '',
      username: '',
      message: '',
      phone: '',
      emil: '',
      imgdata: []
    }
  },
  methods: {
    async submit() {
      const phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (this.phone) {
        if (!phonereg.test(this.phone)) {
          Toast('手机号格式不正确')
          return
        }
      }
      if (!this.title) {
        Toast('请填写标题')
      } else if (!this.message) {
        Toast('请添加描述')
      } else {
        let str = ''
        this.imgdata.forEach(res => {
          str = str + res + ';'
        })
        const obj = {
          title: this.title,
          content: this.message,
          contact: this.username,
          phone: this.phone,
          email: this.emil,
          imgList: str
        }
        const obj1 = {}
        for (const k in obj) {
          if (obj[k]) {
            obj1[k] = obj[k]
          }
        }
        console.log(obj1)
        const { data } = await commitFeedback(obj1)
        if (data.code === 200) {
          Toast.success('提交成功')
          setTimeout(() => {
            this.$router.push('/appointment')
          }, 1000)
        }
      }
    },
    async afterRead(a) {
      console.log(a)
      const { data } = await file({
        fileName: a.file.name
      })
      data.data.body.file = a.file
      const formData = new FormData()
      for (const key in data.data.body) {
        formData.append(key, data.data.body[key])
      }
      axios({
        method: 'post',
        url: data.data.post,
        data: formData
      }).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.imgdata.push(data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding-top: 15px;
  box-sizing: border-box;
  width: 100%;
  .title {
    font-size: 14px;
    color: #333;
    margin-left: 5%;
    margin-bottom: 15px;
  }
  .two {
    margin-top: 15px;
  }
  .xx {
    color: #ee0a24;
    font-size: 14px;
  }
  .but {
    border: none;
    outline: none;
    width: 88%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #ffffff;
    background-color: #f8892f;
    text-align: center;
    border-radius: 25px;
    margin: 20px 0 20px 6%;
  }
  .up {
    height: 12vw;
    width: 12vw;
    border: 1px #909090 dashed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .up-box {
    display: flex;
    align-items: center;
    img {
      height: 12vw;
      border: 1px #909090 dashed;
      width: 12vw;
      margin-right: 1vw;
    }
  }
  .updata {
    /deep/ .van-field__control {
      display: flex;
      align-items: center;
    }
  }
}
</style>
